<template>
  <b-card title="Lições">
    <b-alert v-model="showDismissibleAlert" v-height-fade.appear :variant="typeAlert" dismissible class="mb-1">
      <div class="alert-body">
        {{ msg }}
      </div>
    </b-alert>
    <b-overlay id="overlay-background" :show="isLoading" :variant="variant" :opacity="opacity" :blur="blur" rounded="sm">
      <b-card-text>
        Insira todos os dados para fazer o upload da lição
      </b-card-text>
      <b-row class="mb-1">
        <b-col md="6">
          <b-form-input required v-model="name" class="d-inline-block mr-1" placeholder="Nome" />
        </b-col>
        <b-col md="6">
          <b-form-input v-model="description" class="d-inline-block mr-1" placeholder="Descrição" />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4" class="mb-1">
          <date-picker v-model="dtFiltro" range format="DD/MM/YYYY" lang="pt-br"></date-picker>
        </b-col>
        <b-col md="2" class="mb-1">
          <b-form-select v-model="typeId" :options="options"></b-form-select>
        </b-col>
        <b-col md="6">
          <b-form-file :multiple="false" v-model="file" placeholder="Selecione ou arraste o arquivo"
            drop-placeholder="Selecione ou arraste o arquivo" />
        </b-col>
      </b-row>
      <b-row style="float: right">
        <b-col md="12" class="mb-1">
          <b-button size="sm" variant="primary" @click="salvar()">Salvar</b-button>
        </b-col>
      </b-row>

      <b-table :items="files" responsive :fields="tableColumns" primary-key="id" show-empty
        :empty-text="isLoading ? '' : 'Nenhum arquivo encontrado'" :foot-clone="isLoading" class="position-relative"
        stacked="sm">
        <template #cell(tipeId)="data">
          {{ data.item.typeId==1? 'PG' : 'Célula Mater' }}
        </template>
        <template #cell(initialDate)="data">
          {{ (new Date(data.item.initialDate)).toLocaleDateString('pt-BR') }}
        </template>
        <template #cell(finalDate)="data">
          {{ (new Date(data.item.finalDate)).toLocaleDateString('pt-BR') }}
        </template>
        <!-- Column: Id -->
        <template #cell(key)="data">
          <b-button variant="primary btn-sm" @click="download(data.item.key, data.item.name)" class="font-weight-bold">
            Download
          </b-button>
        </template>
        <template #foot()="data">
          <b-skeleton width="90%" height="15px" class="mb-0 mt-0"></b-skeleton>
        </template>
      </b-table>
    </b-overlay>
  </b-card>
</template>

<script>
import { BRow, BCol, BFormFile, BFormInput, BCardText, BCard, BButton, BTable, BSkeleton, BSpinner, BOverlay, BAlert, BFormSelect } from 'bootstrap-vue'
import DatePicker from "vue2-datepicker"
import "vue2-datepicker/index.css"
import "vue2-datepicker/locale/pt-br"

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BCardText,
    BFormFile,
    BFormInput,
    DatePicker,
    BButton,
    BTable,
    BSkeleton,
    BSpinner,
    BOverlay,
    BAlert,
    BFormSelect
  },
  data() {
    return {
      files: [],
      file: null,
      name: "",
      description: "",
      initialDate: null,
      finalDate: null,
      dtFiltro: [new Date(), new Date()],
      isLoading: false,
      showDismissibleAlert: false,
      msg: "",
      typeAlert: 'danger',
      variant: 'light',
      opacity: 0.85,
      blur: '2px',
      tableColumns: [
        { key: "name", label: "Nome", sortable: true },
        { key: "description", label: "Descrição", sortable: true },
        { key: "tipeId", label: "Tipo", sortable: true },
        { key: "initialDate", label: "Data incial", sortable: true },
        { key: "finalDate", label: "Data final", sortable: true },
        { key: "key", label: "Download", sortable: false },
      ],
      typeId: 1,
      options: [
        { value: 1, text: 'PG'}, { value: 2, text: 'Célula Mater'},
      ]
    }
  },
  created() {
    this.buscarDados();
  },
  methods: {
    buscarDados() {
      this.isLoading = true;

      let dataConsulta = new Date();
      dataConsulta.setDate(dataConsulta.getDate() - 30);

      // add a day
      this.$httpFiles.get("/last/", {
        params: { lastConsultingAt: dataConsulta.toLocaleDateString("en-CA") }
      }).then((response) => {
        this.files = response.data.data.records;
        this.isLoading = false;
      });
    },
    salvar() {
      this.initialDate = this.dtFiltro[0].toLocaleDateString("en-CA");
      this.finalDate = this.dtFiltro[1].toLocaleDateString("en-CA");
      if (this.file && this.name && this.description && this.initialDate && this.finalDate) {
        const form = new FormData();
        form.append("file", this.file);
        form.append("typeId", this.typeId);
        form.append("name", this.name);
        form.append("description", this.description);
        form.append("initialDate", this.initialDate);
        form.append("finalDate", this.finalDate);

        this.isLoading = true;
        try {
          this.$httpFiles
            .post("/", form, { headers: { 'Content-Type': 'multipart/form-data;' } })
            .then((response) => {
              this.isLoading = false;
              if (response.data.successful) {
                this.typeAlert = 'success';
                this.showDismissibleAlert = true;
                this.msg = 'Arquivo salvo com sucesso!';
                this.clearForm();
                this.buscarDados();
              }
            });
        } catch (error) {
          this.isLoading = false;
        }
      } else {
        this.typeAlert = 'danger';
        this.showDismissibleAlert = true;
        this.msg = 'Insira todos os dados';
      }
    },
    download(key, name) {
      this.$httpFiles.get('/' + key, {
        responseType: 'blob', // important
      }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', name + '.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
    },
    clearForm() {
      this.file = null;
      this.name = '';
      this.description = '';
    }
  },
}
</script>
